import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { navigationMapper } from '../../data';

import {
    Layout,
    FullHeight,
    TitleAnimation,
    SplittedButton,
    ScaleAndFadeAnimation,
} from '../../components';

import { useReady, useTranslations } from '../../hooks';

import styles from './NotFound.module.css';

export const NotFound = ({
    data: {
        contentful: {
            notFoundPageCollection: {
                items: [page],
            },
        },
    },
    location,
}) => {
    const {
        seo,
        topText,
        bottomText,
    } = page;

    const isReady = useReady();
    const { backToHome } = useTranslations();

    const seoData = {
        title: `${seo.title} - Agostinho & João Pinto, Lda`,
        description: seo.description,
        image: seo.image?.url,
    };
    const topContentClasses = classNames(styles.topContent, {
        [styles.ready]: isReady,
    });

    return (
        <Layout
            fullHeight
            seoData={ seoData }
            withFooter={ false }
            location={ location }
            className={ styles.main }
            headerVariant="transparent">
            <FullHeight>
                <div className={ styles.container }>
                    <div className={ topContentClasses }>
                        <TitleAnimation startAnimation={ isReady }>
                            <p className={ styles.topText }>
                                { topText }
                            </p>
                        </TitleAnimation>
                    </div>
                    <div className={ styles.bottomContent }>
                        <ScaleAndFadeAnimation startAnimation={ isReady }>
                            <p className={ styles.bottomText }>
                                { bottomText }
                            </p>
                        </ScaleAndFadeAnimation>
                    </div>
                    <SplittedButton
                        to={ navigationMapper.home }
                        className={ styles.splittedButton }>
                        { backToHome }
                    </SplittedButton>
                </div>
            </FullHeight>
        </Layout>
    );
};

NotFound.propTypes = {
    data: PropTypes.shape({
        contentful: PropTypes.shape({
            notFoundPageCollection: PropTypes.shape({
                items: PropTypes.array.isRequired,
            }).isRequired,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
};

export default NotFound;
